<template>
  <div class="contract-plan-list">
    <PageTitle title="合約方案列表" hideBtn />
    <section>
      <el-table v-loading="loading" :data="contractPlanList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column label="合約方案" prop="name" align="center" fixed="left" />
        <el-table-column label="可綁定上限" prop="shopLimit" align="center" />
        <el-table-column label="已綁定數量" prop="shopCount" align="center" />
        <el-table-column label="包含模組" prop="modules" align="center" width="300" />
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="contractCount"
        @pageChange="changePage"
      />
    </section>
  </div>
</template>

<script>
import { moduleText } from '@/config/contractPlan'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetContractPlan, GetContractPlanCount } from '@/api/contract'

// Utils
import { pageStartIndex } from '@/utils/table'

export default {
  name: 'ContractPlanList',
  components: { EmptyBlock },
  data: () => ({
    loading: false,
    selectRow: null,

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    contractPlanList: [],
    contractCount: 0,
  }),

  computed: {
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

  },

  async mounted () {
    await this.getContractPlan()
    await this.getContractPlanCount()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'success'
      if (!val) type = 'danger'
      return type
    },

    showInput (typeList) {
      return !!typeList.includes(this.dialogType)
    },

    async changePage () {
      await this.getContractPlan()
      await this.getContractPlanCount()
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    //= > 取得合約
    async getContractPlan () {
      try {
        this.contractPlanList = (await GetContractPlan({ start: this.pageStartIndex, limit: this.tableOptions.pageLimit })).map(one => ({
          ...one,
          modules: Object.keys(one).map(key => {
            if (one[key] === true && moduleText[key]) {
              return moduleText[key]
            }
            return null
          }).filter(one => one !== null).join('、'),
        }))
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得總店家數量
    async getContractPlanCount () {
      try {
        this.contractCount = await GetContractPlanCount({})
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
