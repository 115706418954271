import { admin } from '@/api/instance'

//= > 取得合約
export const GetContractPlan = async ({
  start,
  limit,
}) => {
  const res = await admin({
    method: 'get',
    url: '/contractPlan',
    params: {
      start,
      limit,
    },
  })

  return res.data
}

//= > 取得合約總數
export const GetContractPlanCount = async ({
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: '/contractPlan/count',
    params: {
      name,
    },
  })

  return res.data.count
}

//= > 取得特定合約
export const FindContractPlan = async ({
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `/contractPlan/${id}`,
  })

  return res.data
}

// //=> 更新合約
// export const UpdateContractPlan = async ({
//   orgId,
//   id,
//   contractId,
//   name,
//   shopLimit,
//   useAppointment,
//   appointmentUnitLimit,
//   appointmentReservationLimitPerMonth,
//   useSales,
//   useClassTicket,
//   useEcommerce,
// }) => {
//   const res = await admin({
//     method: 'put',
//     url: `/org/${orgId}/contractPlan/${id}`,
//     data: {
//       contractId,
//       name,
//       shopLimit,
//       useAppointment,
//       appointmentUnitLimit,
//       appointmentReservationLimitPerMonth,
//       useSales,
//       useClassTicket,
//       useEcommerce,
//     }
//   })

//   return res.data
// }

// //=> 刪除合約
// export const DeleteContractPlan = async ({
//   orgId,
//   id,
// }) => {
//   const res = await admin({
//     method: 'delete',
//     url: `/org/${orgId}/contractPlan/${id}`,
//   })

//   return res.data
// }
